import { Menu, Transition } from '@headlessui/react';
import Link from 'next/link';
import React from 'react';
import { Logo } from '../logo';
import { Pattern } from '../pattern';

const navTeams = [
  { href: '/teams/kampfmannschaft', title: 'Kampfmannschaft' },
  { href: '/teams/juniors', title: 'Juniors' },
  { href: '/teams/frauen', title: 'Frauen' },
  { href: '/teams/u18', title: 'U18' },
  { href: '/teams/u16', title: 'U16' },
  { href: '/teams/u15', title: 'U15' },
  { href: '/teams/u14', title: 'U14' },
  { href: '/teams/u13', title: 'U13' },
  { href: '/teams/u12', title: 'U12' },
  { href: '/teams/u11', title: 'U11' },
  { href: '/teams/u10-a', title: 'U10 A' },
  { href: '/teams/u10-b', title: 'U10 B' },
  { href: '/teams/u08', title: 'U08' },
  { href: '/teams/u07', title: 'U07' },
];

const navClub = [
  { href: '/verein/kontakt', title: 'Kontakt' },
  { href: '/verein/funktionaere', title: 'Funktionäre' },
  { href: '/verein/sponsoren', title: 'Sponsoren' },
];

export const Nav = (): React.JSX.Element => {
  return (
    <nav className="relative z-20 border-b border-b-blue-500 bg-blue-700">
      <Pattern className="absolute inset-0 h-full w-full text-black" />
      <ul className="relative mx-auto flex h-12 max-w-5xl justify-evenly text-center text-sm md:h-24 md:text-lg">
        <NavLi>
          <Link href="/">
            <NavText>Home</NavText>
          </Link>
        </NavLi>
        <NavLi>
          <Link href="/news">
            <NavText>News</NavText>
          </Link>
        </NavLi>
        <li className="w-1/5 pt-0.5 md:pt-3" aria-hidden>
          <Link href="/" className="inline-block" tabIndex={-1}>
            <Logo className="mx-auto box-content w-16 p-1 drop-shadow-md transition-transform hover:scale-105 md:w-28" />
          </Link>
        </li>
        <NavLi>
          <NavSub title="Teams" items={navTeams} />
        </NavLi>
        <NavLi>
          <NavSub title="Verein" items={navClub} />
        </NavLi>
      </ul>
    </nav>
  );
};

const NavSub = ({ title, items }: { title: string; items: { href: string; title: string }[] }): React.JSX.Element => {
  return (
    <Menu as="div">
      <Menu.Button>
        <NavText>{title}</NavText>
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="drop-shadow-md">
          <div className="absolute -bottom-2 left-1/2 -ml-1 h-2 w-2 rotate-45 bg-white" />
          <div className="absolute right-0 mt-1 w-max origin-top-right divide-y divide-gray-100 rounded-xs bg-white p-1 text-center md:right-1/2 md:origin-top md:translate-x-1/2">
            {items.map(item => (
              <Menu.Item key={item.href}>
                {({ active }) => (
                  <NextLink
                    href={item.href}
                    className={`${
                      active ? 'bg-blue-500 text-white' : ''
                    } block w-full rounded-sm p-2 text-sm hover:bg-blue-500 hover:text-white`}>
                    {item.title}
                  </NextLink>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const NavLi = ({ children }: { children: React.ReactNode }): React.JSX.Element => (
  <li className="flex w-1/5 items-center justify-center">{children}</li>
);

const NavText = ({ children }: { children: React.ReactNode }): React.JSX.Element => (
  <span className="group peer relative inline-block px-4 py-3.5 text-white md:font-medium">
    {children}
    <span className="pointer-events-none absolute right-4 bottom-2 left-full h-0.5 bg-white opacity-0 transition-[left,opacity] duration-500 group-hover:left-4 group-hover:opacity-100 group-focus:left-4 group-focus:opacity-100" />
  </span>
);

const NextLink = React.forwardRef<HTMLAnchorElement, React.ComponentPropsWithoutRef<'a'> & { href: string }>(
  ({ href, children, ...props }, ref): React.JSX.Element => (
    <Link href={href} ref={ref} {...props}>
      {children}
    </Link>
  ),
);
NextLink.displayName = 'NextLink';
