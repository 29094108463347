import { EnvelopeIcon, NewspaperIcon, PhoneIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';
import type React from 'react';
import { Container } from '../container';
import { Logo } from '../logo';

export const Footer = (): React.JSX.Element => (
  <div className="bg-blue-800">
    <Container>
      <div className="grid gap-12 py-24 sm:grid-cols-2 md:gap-6 lg:grid-cols-4">
        <Column>
          <H>Adresse</H>
          <Item href="https://goo.gl/maps/sPAMA2wdnZa6d7ZV9">
            LSP Arena Gmunden
            <br />
            Karl Plentzner Stasse 9a
            <br />
            4810 Gmunden
            <br />
            Österreich
          </Item>
        </Column>

        <Column>
          <H>Kontakt</H>
          <Items>
            <Item href="mailto:office@svg1921.at">
              <EnvelopeIcon className="mr-2 h-4 w-4 text-white" /> office@svg1921.at
            </Item>
            <Item href="tel:+436764143409">
              <PhoneIcon className="mr-2 h-4 w-4 text-white" /> +43 676 4143409
            </Item>
            <Item href="tel:+43761273400209">
              <FaxIcon className="mr-2 h-4 w-4 text-white" /> +43 7612 7340020
            </Item>
          </Items>
        </Column>

        <Column>
          <H>Links</H>
          <Items>
            <Item href="https://www.facebook.com/svgmunden1921/">
              <FacebookIcon className="mr-2 h-4 w-4" /> SVG auf Facebook
            </Item>
            <Item href="https://www.instagram.com/svgmunden1921/">
              <InstagramIcon className="mr-2 h-4 w-4" /> SVG auf Instagram
            </Item>
            <Item href="https://vereine.oefb.at/SVGmundnerMilch/">
              <BallIcon className="mr-2 h-4 w-4 text-white" /> SVG auf ÖFB
            </Item>
            <Item href="https://ticker.ligaportal.at/mannschaft/64/sv-gmundner-milch/">
              <EmojiEventsIcon className="mr-2 h-4 w-4 text-white" /> SVG auf Ligaportal
            </Item>
          </Items>
        </Column>

        <Column>
          <H>Newsletter</H>
          <Items>
            <Item href="https://038c2241.sibforms.com/serve/MUIEAIwdIAvqClNvgAONKeDFLAnV_xcB4xo19I7gi08vKwTqE5xQeWzx1eYTNO2f0JxaB-4um89blTy_ShYByC_nEX0TNcihXP_81iuc03taicCsTCSuKwWxHRRq3B2sfqewPWYU-sY8pP13DdI99gXyB0WsYspE_pdNSMY7xtVnLknQvMF1mSmG9SvMgYS5a4UGib22bIjrzUdj">
              <NewspaperIcon className="mr-2 h-4 w-4 text-white" /> Jetzt anmelden
            </Item>
          </Items>
        </Column>
      </div>

      <div className="flex flex-wrap justify-between border-t border-white/10 py-4 text-sm whitespace-nowrap text-gray-300/80 sm:text-base">
        <Link href="/" className="inline-flex items-center py-2 hover:text-white hover:underline">
          <Logo className="mr-2 h-5 w-5" />
          SV Gmundner Milch
        </Link>
        <div className="inline-flex space-x-4 py-2 sm:space-x-8">
          <Link href="/impressum" className="hover:text-white hover:underline">
            Impressum
          </Link>
          <Link href="/datenschutz" className="hover:text-white hover:underline">
            Datenschutz
          </Link>
          <Link href="https://9200.io" className="hover:text-white hover:underline">
            Developed by 9200.io
          </Link>
        </div>
      </div>
    </Container>
  </div>
);

const Column = ({ children }: { children: React.ReactNode }): React.JSX.Element => (
  <div className="space-y-6">{children}</div>
);

const H = ({ children }: { children: React.ReactNode }): React.JSX.Element => (
  <div className="text-xl font-bold text-white">{children}</div>
);

const Items = ({ children }: { children: React.ReactNode }): React.JSX.Element => (
  <div className="flex flex-col items-start space-y-3.5">{children}</div>
);

const Item = ({ href, children }: { href: string; children: React.ReactNode }): React.JSX.Element => (
  <Link
    href={href}
    className="inline-flex items-center leading-loose text-gray-300/80 hover:text-white hover:underline"
    target="_blank"
    rel="nofollow">
    {children}
  </Link>
);

// https://github.com/edent/SuperTinyIcons
const FacebookIcon = (props: React.ComponentProps<'svg'>): React.JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <rect width="512" height="512" rx="15%" fill="#1877f2" />
    <path
      d="M355.6 330l11.4-74h-71v-48c0-20.2 9.9-40 41.7-40H370v-63s-29.3-5-57.3-5c-58.5 0-96.7 35.4-96.7 99.6V256h-65v74h65v182h80V330h59.6z"
      fill="#fff"
    />
  </svg>
);

// https://github.com/edent/SuperTinyIcons
const InstagramIcon = (props: React.ComponentProps<'svg'>): React.JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" {...props}>
    <rect width="512" height="512" rx="15%" id="b" />
    <use fill="url(#a)" xlinkHref="#b" />
    <use fill="url(#c)" xlinkHref="#b" />
    <radialGradient id="a" cx=".4" cy="1" r="1">
      <stop offset=".1" stopColor="#fd5" />
      <stop offset=".5" stopColor="#ff543e" />
      <stop offset="1" stopColor="#c837ab" />
    </radialGradient>
    <linearGradient id="c" x2=".2" y2="1">
      <stop offset=".1" stopColor="#3771c8" />
      <stop offset=".5" stopColor="#60f" stopOpacity="0" />
    </linearGradient>
    <g fill="none" stroke="#fff" strokeWidth="30">
      <rect width="308" height="308" x="102" y="102" rx="81" />
      <circle cx="256" cy="256" r="72" />
      <circle cx="347" cy="165" r="6" />
    </g>
  </svg>
);

// https://fonts.google.com/icons
const BallIcon = (props: React.ComponentProps<'svg'>): React.JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M13,5.3l1.35-0.95 c1.82,0.56,3.37,1.76,4.38,3.34l-0.39,1.34l-1.35,0.46L13,6.7V5.3z M9.65,4.35L11,5.3v1.4L7.01,9.49L5.66,9.03L5.27,7.69 C6.28,6.12,7.83,4.92,9.65,4.35z M7.08,17.11l-1.14,0.1C4.73,15.81,4,13.99,4,12c0-0.12,0.01-0.23,0.02-0.35l1-0.73L6.4,11.4 l1.46,4.34L7.08,17.11z M14.5,19.59C13.71,19.85,12.87,20,12,20s-1.71-0.15-2.5-0.41l-0.69-1.49L9.45,17h5.11l0.64,1.11 L14.5,19.59z M14.27,15H9.73l-1.35-4.02L12,8.44l3.63,2.54L14.27,15z M18.06,17.21l-1.14-0.1l-0.79-1.37l1.46-4.34l1.39-0.47 l1,0.73C19.99,11.77,20,11.88,20,12C20,13.99,19.27,15.81,18.06,17.21z"
    />
  </svg>
);

// https://fonts.google.com/icons
const FaxIcon = (props: React.ComponentProps<'svg'>): React.JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M19,9h-1V4H8v14.5V20h14v-8C22,10.34,20.66,9,19,9z M10,6h6v3h-6V6z M14,17h-4v-5h4V17z M16,17c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1s1,0.45,1,1C17,16.55,16.55,17,16,17z M16,14c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1 C17,13.55,16.55,14,16,14z M19,17c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C20,16.55,19.55,17,19,17z M19,14 c-0.55,0-1-0.45-1-1c0-0.55,0.45-1,1-1s1,0.45,1,1C20,13.55,19.55,14,19,14z"
    />
    <path
      fill="currentColor"
      d="M4.5,8C3.12,8,2,9.12,2,10.5v8C2,19.88,3.12,21,4.5,21S7,19.88,7,18.5v-8C7,9.12,5.88,8,4.5,8z"
    />
  </svg>
);

// https://fonts.google.com/icons
const EmojiEventsIcon = (props: React.ComponentProps<'svg'>): React.JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M19,5h-2V3H7v2H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94c0.63,1.5,1.98,2.63,3.61,2.96V19H7v2h10v-2h-4v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M12,14 c-1.65,0-3-1.35-3-3V5h6v6C15,12.65,13.65,14,12,14z M19,8c0,1.3-0.84,2.4-2,2.82V7h2V8z"
    />
  </svg>
);
