import type React from 'react';

export const Pattern = (props: React.ComponentProps<'svg'>): React.JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1893 1346" preserveAspectRatio="xMidYMid slice" {...props}>
    <path
      fill="currentColor"
      fillOpacity="7%"
      d="M0 673V0h658l40 23v128l280 161v509l-72 42-1 241v242H0zm1204 461V921l430-248 259 150v523h-689v-212zm-33-440-74-43V479l148-86 149 86v172l-75 43-74 43-75-43zm417-283-158-92-1-159V0h464v418l-73 42-74 42-158-91z"
    />
    <path
      fill="currentColor"
      fillOpacity="8%"
      d="M1346 1180v-167l152-87 153-89 121 69 119 70 1 185v185h-546zM317 948 98 821l-1-64v-64L0 638v-54l97-55V312l220-126L538 59l159 91 1 192v190l-29 16-30 18c-2 1 13 10 132 79l134 77v141L721 969l-184 106-220-127zm877-296-50-28V507l50-29c27-16 50-28 51-28l51 28 50 29v117l-50 28-51 29-51-29zm459-378-108-62V0h347v260l-64 37-66 38c-1 1-13-6-109-61z"
    />
    <path
      fill="currentColor"
      fillOpacity="9%"
      d="m177 740-80-46V528l236-136 151 87 151 88c1 1-375 218-377 219l-81-46z"
    />
  </svg>
);
