import Head from 'next/head';
import type React from 'react';

export const MetaHelper = ({
  url,
  siteName,
  themeColor,
  title,
  description,
  image,
  locale,
  locales,
  defaultLocale,
}: {
  url: URL;
  siteName: string;
  themeColor: string;
  title: string;
  description: string;
  image: string;
  locale: string;
  locales: string[];
  defaultLocale: string;
}): React.JSX.Element => {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <title>{title.includes(siteName) ? title : title + ' – ' + siteName}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={cleanUrl(url, locale, locales, defaultLocale)} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content={locale.split('-').join('_')} />
      <meta property="fb:app_id" content="966242223397117" />
      <meta name="twitter:card" content="summary_large_image" />

      <link href="/favicon.ico" rel="icon" sizes="32x32" />
      <link href="/assets/favicons/icon.svg" rel="icon" type="image/svg+xml" />
      <link href="/assets/favicons/icon-apple.png" rel="apple-touch-icon" />
      <link href="/assets/favicons/site.webmanifest" rel="manifest" />
      <meta content={themeColor} name="theme-color" />

      <link rel="canonical" href={cleanUrl(url, locale, locales, defaultLocale)} />
      {locales.length > 1 && (
        <>
          <link rel="alternate" hrefLang="x-default" href={cleanUrl(url, defaultLocale, locales, defaultLocale)} />
          {locales.map(locale => (
            <link key={locale} rel="alternate" hrefLang={locale} href={cleanUrl(url, locale, locales, defaultLocale)} />
          ))}
        </>
      )}
    </Head>
  );
};

const cleanUrl = (url: URL, locale: string, locales: string[], defaultLocale: string): string =>
  locale ? addLocale(url, locale, locales, defaultLocale).toString() : removeLocale(url, locales).toString();

const removeLocale = (url: URL, locales: string[]): URL => {
  const { protocol, host, pathname } = url;
  const path = pathname.replace(new RegExp('^/(' + locales.join('|') + ')[/]?'), '/');
  return new URL(protocol + '//' + host + path);
};

const addLocale = (url: URL, locale: string, locales: string[], defaultLocale?: string): URL => {
  const { protocol, host, pathname } = removeLocale(url, locales);
  const path = pathname.replace(new RegExp('^/(' + locales.join('|') + ')[/]?'), '/');
  if (locale === defaultLocale) {
    return new URL(protocol + '//' + host + path);
  } else if (path === '/') {
    return new URL(protocol + '//' + host + '/' + locale);
  } else {
    return new URL(protocol + '//' + host + '/' + locale + path);
  }
};
