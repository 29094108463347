import type React from 'react';
import { Footer } from './footer';
import { Meta } from './meta';
import { Nav } from './nav';

export const Layout = ({
  title,
  description,
  image,
  children,
}: {
  title: string;
  description?: string;
  image?: string | null;
  children: React.ReactNode;
}): React.JSX.Element => {
  return (
    <div className="flex min-h-dvh flex-col">
      <Meta title={title} description={description} image={image} />
      <Nav />
      <div className="relative grow">{children}</div>
      <Footer />
    </div>
  );
};
